import React from "react"
import { connect } from "react-redux"
import { Button, CircularProgress, Popover } from "@material-ui/core"
import NumberFormat from "../../../common/components/numberFormat"
import Consts from "../../../app/consts"
import { hasRoasEnabled } from "../../../utils/campaignUtilsV2"
import AuthApi from "../../../api/authService"
import ModuleTypes from "../../../common/consts/moduleTypes"
import { stopPropagationIfParentIsSelected } from "../../../utils/domUtils"
import CampaignManualBid from "./campaignManualBid"
import CampaignRoas from "./campaignRoas"

class CampaignBidPopup extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showWarning: false,
      warningMessage: "",
      warningCallback: null,
      popupAnchorEl: null,
      popupOpen: false,
      localRoasStatus: false,
      isChangeBidAllowed: false,
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.popupOpen ||
      this.state.popupOpen !== nextState.popupOpen ||
      this.props.campaign.isCampaignBidLoading !== nextProps.campaign.isCampaignBidLoading ||
      this.props.campaign.bid !== nextProps.campaign.bid
    )
  }

  openPopup = (event) => {
    stopPropagationIfParentIsSelected(event)
    let isRoasEnabled = hasRoasEnabled(this.props.campaign)

    this.setState({
      popupOpen: true,
      popupAnchorEl: event.currentTarget,
      localRoasStatus: isRoasEnabled,
    })
  }

  closePopup = () => {
    this.setState(
      {
        popupOpen: false,
      },
      () => {
        this.setState({
          showWarning: false,
        })
      }
    )
  }

  showWarning = (message, callback, event) => {
    this.setState({
      showWarning: true,
      warningMessage: message,
      warningCallback: callback,
    })
  }

  componentWillMount() {
    let isChangeBidAllowed = AuthApi.hasModule(ModuleTypes.CAMPAIGN_BID_CHANGE)
    if (isChangeBidAllowed) {
      this.setState({
        isChangeBidAllowed,
      })
    }
  }

  getWarningForm = () => {
    return (
      <form onSubmit={(event) => this.state.warningCallback(event)}>
        <div className="bid-warning">
          <span className="message">{this.state.warningMessage}</span>
          <Button
            className="round-button green"
            onClick={(event) => {
              this.state.warningCallback(event)
            }}
          >
            Change
          </Button>
          <Button className="round-button cancel" onClick={this.closePopup}>
            Cancel
          </Button>
        </div>
      </form>
    )
  }

  render() {
    let isClickable = true
    let warningDialog = null
    let campaignBidPopup = null
    let roasEnabled = hasRoasEnabled(this.props.campaign)
    let roasIndicator = null

    if (roasEnabled && this.props.showRoasIndicator) {
      roasIndicator = <div className="roas-on">ROAS</div>
    }

    let loaderDiv = (
      <div className="loading-indicator">
        <CircularProgress size={20} />
      </div>
    )
    let campaignBidLoadingIndicator = this.props.campaign.isCampaignBidLoading ? loaderDiv : null
    let bidValue = 0

    if (!hasRoasEnabled(this.props.campaign)) {
      bidValue = <NumberFormat value={this.props.campaign.bid} displayType="text" decimalScale={3} prefix="$" />
    } else if (this.props.campaign.roas) {
      bidValue = <NumberFormat value={this.props.campaign.roas} displayType="text" decimalScale={0} suffix="%" />
    }

    if (!this.state.isChangeBidAllowed || this.props.disablePopup) {
      // Changing bid and automation data are not allowed -
      // This box will not be clickable and we won't render the popup
      isClickable = false
    } else {
      if (this.state.showWarning) {
        warningDialog = this.getWarningForm()
      }

      campaignBidPopup = (
        <Popover
          open={this.state.popupOpen}
          anchorEl={this.state.popupAnchorEl}
          onClose={this.closePopup}
          classes={{ paper: "campaign-bid-popup-wrapper" }}
          anchorOrigin={{
            vertical: -22,
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          getContentAnchorEl={null}
          transitionDuration={{ enter: 200, exit: this.state.showWarning ? 0 : 200 }}
          onClick={(event) => event.stopPropagation()}
        >
          <div className="campaign-bid-popup">
            {warningDialog}
            <CampaignManualBid
              closePopup={this.closePopup}
              showWarning={this.showWarning}
              campaign={this.props.campaign}
              shouldShowWarning={this.state.showWarning}
              isChangeBidAllowed={this.state.isChangeBidAllowed}
              localRoasStatus={this.state.localRoasStatus}
            />
            <CampaignRoas
              closePopup={this.closePopup}
              campaign={this.props.campaign}
              localRoasStatus={this.state.localRoasStatus}
              isChangeBidAllowed={this.state.isChangeBidAllowed}
            />
          </div>
        </Popover>
      )
    }

    return (
      <div className="campaign-bid">
        <div onClick={isClickable ? this.openPopup : null} className={isClickable ? "clickable" : ""}>
          {bidValue}
          <div className="bid-underline" />
          {roasIndicator}
        </div>
        {campaignBidPopup}
        {campaignBidLoadingIndicator}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {}
}

export default connect(mapStateToProps)(CampaignBidPopup)
