import React from "react"
import ActionTypes from "./actionTypes"
import SavedFiltersService from "../../api/savedFiltersService"
import CampaignServiceV2 from "../../api/campaignServiceV2"
import AppService from "../../api/appService"
import mixpanel from "mixpanel-browser"
import Consts, {
  TAG_LENGTH_ERROR_MESSAGE,
  TAG_IS_NOT_ASCII_ERROR_MESSAGE,
  MAX_TAGS_PER_CAMPAIGN_ERROR_MESSAGE,
} from "../../app/consts"

const MIX_PANEL_TOKEN = "a794607be0f1b99bf2293641b77e80af"

export function appActive() {
  return { type: ActionTypes.APP_ACTIVE }
}

export function appInactive() {
  return { type: ActionTypes.APP_INACTIVE }
}

export function createInitialResourcesLoaded(
  user,
  countries,
  languages,
  sites,
  conversionEvents,
  providersList,
  countryGroups
) {
  return {
    type: ActionTypes.ALL_RESOURCES_LOADED,
    user,
    countries,
    languages,
    sites,
    conversionEvents,
    providersList,
    countryGroups,
  }
}

export function resetApplication() {
  return { type: ActionTypes.RESET_APPLICATION }
}

export function appCurrentVersion(currentVersion, currentVersionDate) {
  return { type: ActionTypes.APP_CURRENT_VERSION, currentVersion, currentVersionDate }
}

export function createSiteSelected(network, site) {
  return { type: ActionTypes.SITE_SELECTED, network, site }
}

export function createNetworkSelected(network) {
  return { type: ActionTypes.NETWORK_SELECTED, network }
}

export function clearNotifications(notificationType = null) {
  return { type: ActionTypes.CLEAR_NOTIFICATIONS, notificationType }
}

export function notificationAdd(message, notificationType = "error", getAdditionalNotificationElement = null) {
  let metadata = {
    message: message,
  }
  window.Intercom("trackEvent", "error", metadata)
  return { type: ActionTypes.NOTIFICATION_ADD, message, notificationType, getAdditionalNotificationElement }
}

export function notificationRemove(item) {
  return { type: ActionTypes.NOTIFICATION_REMOVE, item }
}

export function customInterfacesLoadedV2(customInterfaceV2) {
  return { type: ActionTypes.CUSTOM_INTERFACES_LOADED_V2, customInterfaceV2 }
}

export function savedFiltersLoaded(savedFilters) {
  return { type: ActionTypes.SAVED_FILTERS_LOADED, savedFilters }
}

export function mixpanelIdentifyUser(userDataId) {
  mixpanel.init(MIX_PANEL_TOKEN)
  mixpanel.identify(userDataId)
}

export function addSavedFilters(savedFilters) {
  return { type: ActionTypes.ADD_SAVED_FILTERS, savedFilters }
}

export function addSavedFiltersGroup(sectionId, groupName, filterData) {
  return (dispatch) => {
    return SavedFiltersService.addSavedFilters(sectionId, groupName, filterData).then((response) => {
      dispatch(addSavedFilters(response.data.savedFilters))
    })
  }
}

export function removeSavedFilters(filterId) {
  return { type: ActionTypes.DELETE_SAVED_FILTERS, filterId }
}

export function deleteSavedFilters(filterId) {
  return (dispatch) => {
    SavedFiltersService.deleteSavedFilters(filterId)
      .then((response) => {
        dispatch(removeSavedFilters(filterId))
      })
      .catch((error) => {
        // handle error
      })
  }
}

export function getNetworkTagsV2(networkCode, localTags) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.RESET_NETWORK_TAGS_V2 })
    return CampaignServiceV2.getNetworkTags(networkCode).then((result) => {
      dispatch({ type: ActionTypes.NETWORK_TAGS_RESPONSE_V2, networkTags: result.data.network_tags, localTags })
    })
  }
}

export function validateTagText(tagName) {
  // check if tagName has only Ascii chars and shorter than MAX_TAG_LENGTH
  if (tagName && !/^[\x00-\x7F]*$/.test(tagName)) {
    return (
      <div className="tag-is-not-ascii-error" key="tag-is-not-ascii-error">
        {TAG_IS_NOT_ASCII_ERROR_MESSAGE}
      </div>
    )
  } else if (tagName && tagName.length > Consts.MAX_TAG_LENGTH) {
    return (
      <div className="max-tag-length-error" key="max-tag-length-error">
        {TAG_LENGTH_ERROR_MESSAGE}
      </div>
    )
  }

  return null
}

export function validateMaxTagsPerCampaign(tags) {
  if (tags.length > Consts.MAX_TAGS_PER_CAMPAIGN) {
    return (
      <div className="max-tags-per-campaign-error" key="max-tags-per-campaign-error">
        {MAX_TAGS_PER_CAMPAIGN_ERROR_MESSAGE}
      </div>
    )
  }

  return null
}

export function validateMaxSelectedTagsPerCampaign(tags) {
  if (tags.length >= Consts.MAX_TAGS_PER_CAMPAIGN) {
    return (
      <div className="max-selected-tags-per-campaign-error" key="max-selected-tags-per-campaign-error">
        {MAX_TAGS_PER_CAMPAIGN_ERROR_MESSAGE}
      </div>
    )
  }

  return null
}
