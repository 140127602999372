import React from "react"
import { CircularProgress, Popover } from "@material-ui/core"
import NumberFormat from "../../../common/components/numberFormat"
import Consts from "../../../app/consts"
import AuthApi from "../../../api/authService"
import ModuleTypes from "../../../common/consts/moduleTypes"
import { stopPropagationIfParentIsSelected } from "../../../utils/domUtils"
import SubSourceManualBid from "./subSourceManualBid"
import { connect } from "react-redux"

class SubSourceBidPopup extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      popupAnchorEl: null,
      popupOpen: false,
      isChangeBidAllowed: false,
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.popupOpen ||
      this.state.popupOpen !== nextState.popupOpen ||
      this.props.subSource.isBidLoading !== nextProps.subSource.isBidLoading ||
      this.props.subSource.bid !== nextProps.subSource.bid
    )
  }

  openPopup = (event) => {
    stopPropagationIfParentIsSelected(event)
    this.setState({
      popupOpen: true,
      popupAnchorEl: event.currentTarget,
    })
  }

  closePopup = () => {
    this.setState(
      {
        popupOpen: false,
      },
      () => {
        this.setState({
          showWarning: false,
        })
      }
    )
  }

  componentWillMount() {
    const isAllowedProvider = Consts.PROVIDERS_THAT_CAN_CHANGE_SUB_SOURCES.includes(this.props.campaign.provider_id)
    const isChangeBidAllowed = AuthApi.hasModule(ModuleTypes.CAMPAIGN_BID_CHANGE) && isAllowedProvider

    if (isChangeBidAllowed) {
      this.setState({
        isChangeBidAllowed,
      })
    }
  }

  render() {
    let isClickable = true
    let bidPopup = null

    let loaderDiv = (
      <div className="loading-indicator">
        <CircularProgress size={20} />
      </div>
    )
    let bidLoadingIndicator = this.props.subSource.isBidLoading ? loaderDiv : null
    let bidValue = <NumberFormat value={this.props.subSource.bid} displayType="text" decimalScale={3} prefix="$" />

    if (!this.state.isChangeBidAllowed) {
      isClickable = false
    } else {
      bidPopup = (
        <Popover
          open={this.state.popupOpen}
          anchorEl={this.state.popupAnchorEl}
          onClose={this.closePopup}
          classes={{ paper: "campaign-bid-popup-wrapper" }}
          anchorOrigin={{
            vertical: -22,
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          getContentAnchorEl={null}
          transitionDuration={{ enter: 200, exit: 200 }}
          onClick={(event) => event.stopPropagation()}
        >
          <div className="campaign-bid-popup">
            <SubSourceManualBid
              closePopup={this.closePopup}
              showWarning={this.showWarning}
              campaign={this.props.campaign}
              subSource={this.props.subSource}
              isChangeBidAllowed={this.state.isChangeBidAllowed}
            />
          </div>
        </Popover>
      )
    }

    return (
      <div className="sub-source-bid">
        <div onClick={isClickable ? this.openPopup : null} className={isClickable ? "clickable" : ""}>
          {bidValue}
          <div className="bid-underline" />
        </div>
        {bidPopup}
        {bidLoadingIndicator}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {}
}

export default connect(mapStateToProps)(SubSourceBidPopup)
