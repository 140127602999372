import React from "react"
import { uuid4 } from "../utils/funcUtils"

let Consts = {
  CAMPAIGN_MANAGEMENT: "campaign-management",
  TRAFFIC_SOURCES: "traffic-sources",
  AUTOMATION_RULES: "rules",
  DASHBOARD: "dashboard",
  DRAFTS: "drafts",
  POLICY_REVIEW: "policy-review",
  ARTICLES: "articles",
  ARTICLES_RSOC: "articles-rsoc",
  GOOGLE_AUTH_CLIENT_ID: process.env.GOOGLE_AUTH_CLIENT_ID,
  AUTH_TOKEN_KEY: "pubplus_auth_token",

  // env variables defined in webpack.config
  ENV: process.env.ENV,
  BASE_URL: process.env.BASE_URL,
  BASE_API_URL: process.env.BASE_API_URL,

  // BASE_INTEGRATIONS_API_URL is temporary for testing, there won't really be one in the future (we will have an
  // api router that redirects all requests to their correct api)
  // BASE_INTEGRATIONS_API_URL: 'http://localhost:5001',
  REDIRECT_URI: process.env.REDIRECT_URI,
  FACEBOOK_API_VERSION: "v17.0",
  PINTEREST_API_VERSION: "v5",

  SERVER_DATE_FORMAT: "YYYY-MM-DD",
  SERVER_TIME_FORMAT: "HH:mm:ss",
  SERVER_DATETIME_FORMAT: "YYYY-MM-DD HH:mm:ss",
  CLIENT_DATE_FORMAT: "MMM DD",
  CLIENT_FULL_DATE_FORMAT: "MMM DD, YYYY",
  CLIENT_FULL_DATE_FORMAT_V2: "MMM DD, YY' (HH:mm)",
  CLIENT_TIME_FORMAT: "HH:mm:ss",
  CLIENT_DATETIME_FORMAT: "MMM DD, HH:mm:ss",
  CLIENT_SHORT_DATE_FORMAT: "D/M/YY",
  CLIENT_FILTER_SHORT_DATE_FORMAT: "DD/MM/YYYY",
  CLIENT_EXPIRATION_DATETIME_FORMAT: "DD/MM/YYYY HH:mm",
  CLIENT_DAY_NAME_FORMAT: "ddd",
  CLIENT_ARTICLE_DATE_TIME_FORMAT: "D/M/YY h:mma",
  CLIENT_DURATION_FORMAT: "m:ss",
  CLIENT_SHORT_TIME_FORMAT: "h:mma",
  CLIENT_SHORT_TIME_WITHOUT_SECONDS_FORMAT: "HH:mm",

  PROVIDER_SELECTED_PAGES_MAX_PAGES_INDEX_ORDER: { 1: 19, 10: 4 },

  UNKNOWN: 0,
  DESKTOP: 1,
  MOBILE: 2,
  TABLET: 3,

  ANDROID: "android",
  IOS: "ios",

  LANGUAGES: {
    en: "English",
    es: "Spanish",
    de: "German",
    fr: "French",
    pt: "Portuguese",
  },

  CURRENCY_SYMBOLS: {
    USD: "$",
    CAD: "$",
    GBP: "£",
    EUR: "€",
    ILS: "₪",
    JPY: "¥",
  },

  FB_LOCALE_TO_LANGUAGE: {
    6: "EN",
    24: "EN",
    7: "ES",
    23: "ES",
  },

  WORLDWIDE_COUNTRY_CODE: "WW",
  WORLDWIDE_COUNTRY_NAME: "World Wide",
  MULTIPLE_COUNTRY_NAME: "Multiple Countries",

  DEFAULT_APP_TITLE: "PubPlus",

  BIG_CHANGE_VALIDATION_TEXT: "Large increase. Save twice to confirm.",
  DEFAULT_MAX_BID: 1,
  DEFAULT_MAX_BUDGET: 50000,
  FACEBOOK_DEFAULT_MAX_BUDGET: 4000,
  BUDGET_CHANGE_VALIDATION_TEXT_MAX: "Budget exceeds maximum",
  BID_CHANGE_VALIDATION_TEXT_MAX: "Bid exceeds maximum",

  PROVIDERS_WITH_TOTAL_BUDGET: [1],
  PROVIDERS_THAT_CAN_CHANGE_SUB_SOURCES: [1],
  STEPPER_STEP_REALTIME_BID_SUB_CENT: 0.001,
  STEPPER_STEP_REALTIME_BID_FULL_CENT: 0.01,
  PROVIDERS_WITH_FULL_CENT: [9, 91, 102], // Facebook, GoogleAds, Pinterest
  PROVIDERS_THAT_CANT_CHANGE_BUDGET: [],
  PROVIDERS_THAT_CANT_CHANGE_STATUS: [],
  PROVIDERS_THAT_CANT_CREATE_CAMPAIGNS: [49, 88, 4, 11, 10],
  STEPPER_STEP_TARGET_ROI: 1,
  STEPPER_STEP_ROAS_ROI: 1,
  CHANGE_BID_BY_ROI_PERCENT_TOLERANCE: 5,

  ALL_NETWORKS_OBJ: {
    ID: 0,
    NAME: "AllNetworks",
  },

  PROVIDER_ACCOUNT_AUTO_SELECT_OBJ: {
    site_id: "FILL THIS!",
    account_id: "AUTO",
    sub_account_id: "AUTO",
    sub_account_name: "AUTO",
    name: "Auto Select Account",
  },

  PROVIDER_PAGE_AUTO_SELECT_OBJ: {
    site_id: "FILL THIS!",
    page_id: "AUTO",
    page_name: "AUTO",
    provider_id: 9,
    page_language: "AUTO",
    ads_limit: "AUTO",
    name: "Auto Select Page",
    key: "auto page",
  },

  UNKNOWN_PROVIDER_ID: 1000001,
  ONE_MINUTE: 60000,
  NOTIFICATION_CHECK_INTERVAL: 30000 /* 30 seconds */,
  REALTIME_TRAFFIC_REFRESH_INTERVAL: 30000 /* 30 seconds */,
  REALTIME_STATS_REFRESH_INTERVAL: 60000 /* 1 minute */,
  CLIENT_VERSION_CHECK_INTERVAL: 5 * 60000 /* 5 minutes */,
  CAMPAIGNS_REPORT_CHECK_INTERVAL: 10 * 60000 /* 10 minutes */,
  CAMPAIGNS_PREDICTED_REPORT_CHECK_INTERVAL: 60000 /* 1 minute */,

  SEARCH_MAX_RESULTS: 20,

  BIG_MULTIPLIER_VALIDATION: 10, //  Validate big change by this multiplier

  NAME_REGEX: RegExp("^(?:(?!(\\s{2}|\\d)).){2,100}$"),
  EMAIL_REGEX: RegExp(
    "^[a-z0-9_-]+(?:\\.[a-z0-9_-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$"
  ),
  PHONE_REGEX: RegExp("^(\\+?\\d{7,12})?$"),

  WHOLE_NUMBER_REGEX: RegExp("^-?[0-9]*$"),
  REAL_NUMBER_REGEX_WITH_PLACES: "^-?[0-9]*.?[0-9]{0,[PLACES]}$",
  URL_REGEX: RegExp(
    "^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$"
  ),
  SPACES_IN_URL_BEFORE_QUERY_STRING_REGEX: /(\s)*(?=\?)/g,
  ENDS_WITH_SLASH_REGEX: /\/$/g,
  ENDS_WITH_PAGES_REGEX: /\/\d+\/$/g,

  CUSTOM_INTERFACE_FIELD_STATUS: {
    SHOW: 1,
    HIDE: 2,
    LOCK: 3,
  },

  DRAWER_PAGE_SIZE: 30,
  BULK_CAMPAIGNS_PER_REQUEST: 10,
  CLIENT_ID: uuid4(),

  DEFAULT_CURRENCY: "USD-USD",

  NUMBER_OF_DISPLAYED_COUNTRIES_IN_SHORT_STRING: 3,

  TAGS_ITEMS_PER_PAGE: 30,
  LAST_TAGS_ITEMS: 3,
  MAX_TAGS_TOOLTIP: 12,
  MAX_TAG_LENGTH: 10,
  MAX_TAGS_PER_CAMPAIGN: 5,

  ACTION_TYPE_STATUS: "status",
  ACTION_TYPE_BID: "bid",
  ACTION_TYPE_ROAS: "roas",
  ACTION_TYPE_BUDGET: "daily_budget",
  ACTION_TYPE_SUB_SOURCE_BID: "sub_source_bid",
  ACTION_TYPE_SUB_SOURCE_BLOCK: "sub_source_block",
  ACTION_TYPE_SUB_SOURCE_STATUS: "sub_source_status",
}

// Apparently, this is the only way to make an object be a const.
Object.freeze(Consts)

export const TAG_LENGTH_ERROR_MESSAGE = "Tags must be less than " + Consts.MAX_TAG_LENGTH + " characters"
export const TAG_IS_NOT_ASCII_ERROR_MESSAGE = "Tag includes unsupported characters"
export const MAX_TAGS_PER_CAMPAIGN_ERROR_MESSAGE =
  "You’ve reached the limit of " + Consts.MAX_TAGS_PER_CAMPAIGN + " tags per campaign"

export default Consts
